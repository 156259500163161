import { createStore } from "vuex";

import auth from "@/store/modules/auth.module";
import enums from "@/store/modules/enum.module";
import i18n from "@/store/modules/i18n.module";
import notifications from "@/store/modules/notification.module";
import body from "@/store/modules/body.module";
import config from "@/store/modules/config.module";
import breadcrumbs from "@/store/modules/breadcrumbs.module";
import registration from "@/store/modules/registration.module";
import loading from "@/store/modules/loading.module";
import datatable from "@/store/modules/datatable.module";
import form from "@/store/modules/form.module";
import automation from "@/store/modules/automation.module";
import routeMeta from "@/store/modules/routemeta.module";
import task from "@/store/modules/task.module";
import reports from "@/store/modules/reports.module";
import coupons from "@/store/modules/coupons.modules";
import campaigns from "@/store/modules/campaigns.module";
import users from "@/store/modules/user.module";
import adChannels from "@/store/modules/adchannels.module";
import adChannelsInCampaigns from "@/store/modules/adchannels-in-campaigns.module";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import securityOptions from "@/helpers/securityOptions";

const ls = new SecureLS(securityOptions);

export default createStore({
  plugins: [
    createPersistedState({
      key: "dgt",
      paths: ["auth", "enums", "i18n", "task", "reports"],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    loading,
    auth,
    enums,
    i18n,
    notifications,
    body,
    config,
    breadcrumbs,
    registration,
    datatable,
    form,
    automation,
    routeMeta,
    task,
    reports,
    coupons,
    campaigns,
    users,
    adChannels,
    adChannelsInCampaigns
  },
});
