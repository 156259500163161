import { Module, Mutation, VuexModule } from "vuex-module-decorators";

interface DataArray {
  [index: number]: Record<string, unknown[]>;
}

@Module({ namespaced: true })
class FormStore extends VuexModule {
  public data: DataArray = [];

  @Mutation
  public addFormData(payload: { _formKey: number; _data: any }): void {
    const { _formKey, _data } = payload;
    this.data[_formKey] = { ..._data };
  }

  @Mutation
  public addFormDataValue(payload: { _formKey: number; _objectKey: string; _value: string }): void {
    const { _formKey, _objectKey, _value } = payload;
    this.data[_formKey][_objectKey]["value"] = _value;
  }

  @Mutation
  public addFormDataValues(payloads: { _formKey: number; _values: { _objectKey: string; _value: string }[] }): void {
    const { _formKey, _values } = payloads;
    _values.forEach(({ _objectKey, _value }) => {
      this.data[_formKey][_objectKey]["value"] = _value;
    });
  }
}

export default FormStore;
