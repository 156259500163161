import axios from "axios";
import { i18n } from "@/helpers/i18nSet";
import { generateRecaptchaToken } from "@/helpers/recaptcha";
import { Endpoints } from "@/store/enums/ApiEnums";
import store from "@/store";

const { t } = i18n.global;

class AuthService {
  login(email: string, password: string) {
    return generateRecaptchaToken("login").then((recaptcha) => {
      return axios
        .post(Endpoints.ENDPOINT_AUTH_LOGIN, {
          email,
          password,
          recaptcha,
        })
        .then(async (response) => {
          if (response.data.token) {
            //localStorage.setItem("user", JSON.stringify(response.data));
            axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
            return response.data;
          }
        });
    });
  }

  logout() {
    localStorage.removeItem("dgt");
    axios.post(Endpoints.ENDPOINT_AUTH_LOGOUT).then(() => {
      axios.defaults.headers.common["Authorization"] = "";
      store.dispatch("i18n/initializeI18n");
      store.dispatch("enums/initializeEnum");
    });

    return t("auth.sign-out-text");
  }

  forgotPassword(email: any) {
    return generateRecaptchaToken("forgotPassword").then((recaptcha) => {
      return axios.post(Endpoints.ENDPOINT_AUTH_PASS_FORGOT, {
        ...email,
        recaptcha,
      });
    });
  }

  resetPassword(data: any) {
    return generateRecaptchaToken("resetPassword").then((recaptcha) => {
      return axios.post(Endpoints.ENDPOINT_AUTH_PASS_RESET, {
        ...data,
        recaptcha,
      });
    });
  }

  /*
  register(username: string, email: string, password: string) {
    return axios.post("auth/register", {
      username,
      email,
      password,
    });
  }*/
}

export default new AuthService();
