import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";

@Module({ namespaced: true })
class UsersStore extends VuexModule {
  //NOTE: partners === publishers === affiliates
  public Partners = {};
  public ActiveUser = {} as any;

  get fetchedPartners() {
    return this.Partners;
  }

  get activeUser() {
    return this.ActiveUser;
  }

  get activeUserAccountId() {
    return this.ActiveUser.account_id;
  }

  @Mutation
  SET_PARTNERS(payload: any): void {
    this.Partners = payload;
  }

  @Mutation
  SET_ACTIVE_USER(payload: any): void {
    this.ActiveUser = payload;
  }

  @Action
  public fetchPartners(query) {
    if (query.length > 2) {
      const filter = "&filter" + "[name]" + "[like]=" + query + "&filter[or]" + "[email]" + "[like]=" + query;
      AxiosService.get(Endpoints.ENDPOINT_USER + "?filter[type][eq]=2" + filter).then(
        (response) => {
          const data = response.data.data;
          this.context.commit("SET_PARTNERS", data);
        },
      );
    } else {
      AxiosService.get(Endpoints.ENDPOINT_USER + "?filter[type][eq]=2").then(
        (response) => {
          const data = response.data.data;
          this.context.commit("SET_PARTNERS", data);
        },
      );
    }
  };

  @Action
  public fetchUserExpandAccount(id) {
    AxiosService.get(Endpoints.ENDPOINT_USER + "/" + id + "&expand[]=account").then(
      (response) => {
        const data = response.data.data;
        this.context.commit("SET_ACTIVE_USER", data);
      },
    );
  };

}

export default UsersStore;
